import { useState, useEffect } from "react";
import "../styling/ResetPassword.css";
import CPD_Logo from "../images/CPD_Logo.png";
import UserIcon from "../images/user.png";
import HidePwd from "../images/hide.png";
import ViewPwd from "../images/view.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../utils/axiosInstance";

export default function ResetPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [proceed, setProceed] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // States for password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // States for password matching
  // const [passwordsMatch, setPasswordsMatch] = useState(true);

  // Check for existing token and validate it
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const storedUsername = localStorage.getItem("username"); // Retrieve stored email or username
      setEmail(storedUsername);
      setProceed(true); // Skip the username/email input phase
    }

    // if (token) {
    //   // Assuming a function isValidToken exists to verify the token validity
    //   axiosInstance
    //     .get("/auth/validate-token", {
    //       headers: { Authorization: `Bearer ${token}` },
    //     })
    //     .then((response) => {
    //       if (response.data.valid) {
    //         // Token is valid, fetch the username from the response or from where it is stored
    //         const storedEmail = localStorage.getItem("email"); // Retrieve stored email or username
    //         setEmail(storedEmail);
    //         setProceed(true); // Skip the username/email input phase
    //       }
    //     })
    //     .catch(() => {
    //       setError("Invalid or expired session. Please log in again.");
    //     });
    // }
  }, []);

  // Handler to check if username/email exists
  const proceedHandler = async () => {
    if (!email) {
      setError("Please enter your username or email.");
      return
    }
    try {
      const response = await axiosInstance.get(
        `/users/check-username/${email}`
      );
      if (response.data.exists) {
        setProceed(true);
        setError("");
      } else {
        setError("Username not found. Try another!");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  const validatePassword = (pwd) => {
    const lengthValid = pwd.length >= 8 && pwd.length <= 12;
    const hasUppercase = /[A-Z]/.test(pwd);
    const hasNumber = /[0-9]/.test(pwd);

    if (newPassword !== confirmPassword) {
      return ("Passwords do not match.");
    }
    if (!lengthValid) {
      return 'Password must be between 8 to 12 characters.';
    }
    if (!hasUppercase) {
      return 'Password must contain at least one uppercase letter.';
    }
    if (!hasNumber) {
      return 'Password must contain at least one number.';
    }

    return ''; // Valid password
  };

  // Handler to change the password
  const resetPasswordHandler = async () => {
    if (oldPassword.trim() === '' || newPassword.trim() === '' || confirmPassword.trim() === '') {
      setError('Passwords cannot be blank.');
      return
    }

    const errMsg = validatePassword(newPassword)
    if (errMsg) {
      setError(errMsg);
    } else {
      setError('');
      // Proceed with further actions (e.g., submitting the password)
      try {
        await axiosInstance.post("/users/change-password", {
          username: email,
          old_password: oldPassword,
          new_password: newPassword,
        });
        setSuccess("Password updated successfully.");
        setError("");
        setTimeout(() => navigate("/login"), 3000); // Redirect to login page after 3 seconds
      } catch (err) {
        setError(
          err.response?.data?.detail || "An error occurred. Please try again."
        );
      }
    }
  };

  // Toggle password visibility
  const toggleVisibility = (type) => {
    if (type === "old") setShowOldPassword(!showOldPassword);
    if (type === "new") setShowNewPassword(!showNewPassword);
    if (type === "confirm") setShowConfirmPassword(!showConfirmPassword);
  };

  // Real-time password matching validation
  // useEffect(() => {
  //   setPasswordsMatch(newPassword === confirmPassword);
  // }, [newPassword, confirmPassword]);

  return (
    <div className="reset-pw-bg">
      <div>
        <img className="cpd-logo" src={CPD_Logo} alt="CPD Logo" />
      </div>
      <div className="resetPW-form">
        <h2 className="reset-header">Reset Password</h2>
        {!proceed ? (
          <form onSubmit={(e) => e.preventDefault()}>
            <p>Please enter your official email-ID</p>
            <div id="email-bar">
              <input
                type="email"
                placeholder="Enter username/email"
                value={email}
                onChange={(e) => {
                  setError("");
                  setEmail(e.target.value)
                }}
              />
              <img src={UserIcon} alt="User Icon" />
            </div>
            {error && <p className="error-text">{error}</p>}
            <div className="w-fill text-center">
              <button className="proceed-btn mx-auto" type="submit" onClick={proceedHandler}>
                Proceed
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="password-field">
              <label htmlFor="old-password">Old Password</label>
              <div id="password-bar">
                <input
                  id="old-password"
                  type={showOldPassword ? "text" : "password"}
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => {
                    setError("");
                    setOldPassword(e.target.value)
                  }}
                />
                <img
                  src={showOldPassword ? HidePwd : ViewPwd}
                  alt="Toggle Password Visibility"
                  onClick={() => toggleVisibility("old")}
                  className="password-toggle-icon"
                />
              </div>
            </div>
            <div className="password-field">
              <label htmlFor="new-password">New Password</label>
              <div id="password-bar">
                <input
                  id="new-password"
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => {
                    setError("");
                    setNewPassword(e.target.value)
                  }}
                />
                <img
                  src={showNewPassword ? HidePwd : ViewPwd}
                  alt="Toggle Password Visibility"
                  onClick={() => toggleVisibility("new")}
                  className="password-toggle-icon"
                />
              </div>
            </div>
            <div className="password-field">
              <label htmlFor="confirm-password">Confirm New Password</label>
              <div id="password-bar">
                <input
                  id="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setError("");
                    setConfirmPassword(e.target.value)
                  }}
                />
                <img
                  src={showConfirmPassword ? HidePwd : ViewPwd}
                  alt="Toggle Password Visibility"
                  onClick={() => toggleVisibility("confirm")}
                  className="password-toggle-icon"
                />
              </div>
              {/* {newPassword !== confirmPassword && confirmPassword.length > 0 && (
                <p className="password-mismatch-text">Passwords do not match</p>
              )} */}
            </div>
            {error && <p className="error-text break-words">{error}</p>}
            {success && <p className="success-text">{success}</p>}
            <div className="w-fill text-center">
              <button
                className="proceed-btn"
                onClick={resetPasswordHandler}
              >
                Reset Password
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
