import { Grid, Card, CardContent, Typography, CardMedia } from "@mui/material"; // Import Material-UI components
import "../styling/AssignedCourses.css";

export default function AssignedCourses({
  CoursesList,
  setShowThumbnail,
  getCourseDetails,
  setStartCourse,
  setEnrollCourse,
}) {
  const selectCourse = (course) => {
    setShowThumbnail(true);
    var courseStatus = course["course_status"] || course["Course_status"];

    // already course is assigned
    if (
      courseStatus === "Complete" ||
      courseStatus === "In Progress" ||
      courseStatus === "Pending"
    ) {
      setStartCourse(true);
      setEnrollCourse(false);
    } else {
      setStartCourse(false);
      setEnrollCourse(true);
    }

    getCourseDetails(
      course["Course_id"],
      course["Course_title"],
      course["Course_banner"]
    );
  };

  return (
    <div className="assigned-courses">
      {/* <h1>Assigned Courses</h1> */}
      {/* Use Grid container for horizontal scrolling */}
      <Grid
        container
        className="asign-courses-list"
        spacing={2}
        // wrap="nowrap" // Ensure items do not wrap to the next line
        sx={{
          overflowX: "auto",
          overflowY: "hidden",
          flexWrap: "nowrap",
          scrollBehavior: "smooth",
          maxWidth: "94vw",
        }}
      >
        {CoursesList.map((course, index) => (
          <Grid item key={index}>
            {/* Use Material-UI Card component for each course */}
            <Card
              onClick={() => selectCourse(course)}
              sx={{
                width: "17vw",
                minHeight: "14.5vw",
                maxHeight: "14.5vw",
                margin: "0 0",
                flex: "0 0 auto",
                borderRadius: "1vw",
              }} // Adjust width and margin for each card
            >
              <CardMedia
                component="img"
                height="140"
                image={course["Course_banner"]}
                alt="course-thumbnail-img"
              />
              <CardContent>
                <Typography
                  // variant="h6"
                  component="div"
                  dangerouslySetInnerHTML={{
                    __html: course["Course_title"] || course["course_title"],
                  }}
                ></Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
