import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../styling/appraisal/history.css";
import axiosInstance from "../../utils/axiosInstance";
import { DataGrid } from "@mui/x-data-grid";

const History = ({ validate, onCreateNewForm, employeeId }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [appraisalData, setAppraisalData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const userRole = localStorage.getItem("role");
  // const userPermissions = JSON.parse(
  //   localStorage.getItem("permissions") || "[]"
  // );
  const userPermissions = localStorage.getItem("permissions");
  const userId = localStorage.getItem("loggedInUserId");

  const fetchData = () => {
    if (userRole === "admin") {
      // Fetch all users for admin
      axiosInstance
        .get("/users/")
        .then((response) => {
          const employeeData = response.data.map((employee) => ({
            id: employee.id,
            name: `${employee.first_name} ${employee.last_name} [${employee.email}]`,
          }));
          setEmployees(employeeData);
        })
        .catch((error) => console.log("Error fetching employees:", error));
    } else if ((userRole === "user") || (userPermissions.includes("normal"))) {
      // Fetch hierarchy for user/editor with 'super' or 'hyper' permission
      axiosInstance
        .get(`/organization/hierarchy/${userId}`)
        .then((response) => {
          const hierarchy = [{
            id: response.data.id,
            name: `${response.data.name} - ${response.data.designation}`,
          }];
          setEmployees(hierarchy);
        })
        .catch((error) => console.log("Error fetching hierarchy:", error));
    } else if (
      (userRole === "user" || userRole === "editor") &&
      (userPermissions.includes("super") || userPermissions.includes("hyper"))
    ) {
      // Fetch hierarchy for user/editor with 'super' or 'hyper' permission
      axiosInstance
        .get(`/organization/hierarchy/${userId}`)
        .then((response) => {
          const hierarchy = response.data.subordinates.map((employee) => ({
            id: employee.id,
            name: `${employee.name} - ${employee.designation}`,
          }));
          setEmployees(hierarchy);
        })
        .catch((error) => console.log("Error fetching hierarchy:", error));
    } else {
      setEmployees([]); // Handle as needed for 'normal' permissions if no access
    }
  };

  const fetchAppraisalData = (id) => {
    axiosInstance
      .get(`/appraisals/history/${id}`)
      .then((response) => {
        const appraisal = response.data.map((item) => ({
          id: item.id,
          employee_id: item.employee_id,
          employee_name: item.employee_name,
          review_date: item.review_date,
          evaluation_start_date: item.evaluation_start_date,
          submission_date: item.submission_date,
          type_of_review: item.type_of_review,
        }));
        setAppraisalData(appraisal);
      })
      .catch((error) => console.log("Error fetching appraisal data:", error));
  };

  useEffect(() => {
    fetchData();
  }, [employeeId, userRole, userPermissions, userId]);

  const handleEmployeeChange = (event, newValue) => {
    setSelectedEmployee(newValue);
    setSelectedRowId(null);
    if (newValue) {
      fetchAppraisalData(newValue.id);
    } else {
      setAppraisalData([]);
    }
  };

  const handleCreateNewForm = () => {
    if (!selectedEmployee) {
      onCreateNewForm(null);
      return;
    }
    onCreateNewForm(selectedEmployee.id);
  };

  const handleRowClick = (appraisalId, empID, action) => {
    if (action === "Edit") {
      if (!selectedEmployee) {
        alert("Please select an employee.");
      } else {
        setSelectedRowId(appraisalId);
        onCreateNewForm(selectedEmployee.id, appraisalId);
      }
    } else if (action === "Preview") {
      onCreateNewForm(empID, appraisalId, action);
    } else if (action === "Delete") {
      deleteAppraisal(appraisalId, empID);
    }
  };

  const deleteAppraisal = (id, empId) => {
    axiosInstance
      .delete(`/appraisals/${id}`)
      .then(() => {
        alert("The appraisal was deleted successfully");
        fetchAppraisalData(empId);
      })
      .catch((err) => console.log("Error deleting appraisal:", err));
  };

  useEffect(() => {
    validate.current = () => true;
  }, [validate]);

  const columns = [
    { field: "review_date", headerName: "Review Date", flex: 1 },
    { field: "type_of_review", headerName: "Type of Review", flex: 1 },
    { field: "evaluation_start_date", headerName: "Evaluation Start Date", flex: 1 },
    { field: "submission_date", headerName: "Submission Date", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div className="icons_div">
            {userRole !== "user" && <EditIcon
              className="cursor-pointer"
              onClick={() =>
                handleRowClick(row.id, row.employee_id, "Edit")
              }
            />}
            <PreviewIcon
              className="cursor-pointer"
              onClick={() =>
                handleRowClick(row.id, row.employee_id, "Preview")
              }
            />
            {userRole !== "user" && <DeleteIcon
              className="cursor-pointer"
              onClick={() =>
                handleRowClick(row.id, row.employee_id, "Delete")
              }
            />}
          </div>
        )
      },
    },
  ];

  return (
    <Box className="history-form">
      <Typography gutterBottom sx={{ fontSize: "1.25vw" }}>
        Appraisal
      </Typography>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={employees}
            getOptionLabel={(option) => option.name}
            onChange={handleEmployeeChange}
            value={selectedEmployee}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Employee Name *"
                placeholder="Search employee name"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        {userRole != "user" && <Grid item xs={12} sm={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleCreateNewForm}
            sx={{
              width: "9.5vw",
              height: "2.5vw",
              fontSize: "0.75vw",
              marginRight: "0.5vw",
            }}
          >
            Create New Form
          </Button>
        </Grid>}
      </Grid>
      <DataGrid
        rows={appraisalData || []}
        columns={columns}
        pagination
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        autoHeight
        className="data-grid mt-4"
        localeText={{ noRowsLabel: selectedEmployee ? "No appraisal data available" : "Please select an employee" }}
      />
      {/* <Box
        sx={{
          marginTop: "1vw",
          height: "400px",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "72.5vw", maxWidth: "72.5vw" }}>
            <TableHead sx={{ minHeight: "2.5vw", maxHeight: "2.5vw" }}>
              <TableRow sx={{ backgroundColor: "#1976d2" }}>
                <TableCell>No.</TableCell>
                <TableCell>Review Date</TableCell>
                <TableCell>Type of Review</TableCell>
                <TableCell>Evaluation Start Date</TableCell>
                <TableCell>Submission Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedEmployee ? (
                appraisalData.length > 0 ? (
                  appraisalData
                    .sort((a, b) => new Date(b.review_date) - new Date(a.review_date))
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        hover
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedRowId === row.id ? "#f0f0f0" : "inherit",
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.review_date}</TableCell>
                        <TableCell>{row.type_of_review}</TableCell>
                        <TableCell>{row.evaluation_start_date}</TableCell>
                        <TableCell>{row.submission_date}</TableCell>
                        <TableCell>
                          <div className="icons_div">
                            {userRole != "user" && <EditIcon
                              onClick={() =>
                                handleRowClick(row.id, row.employee_id, "Edit")
                              }
                            />}
                            <PreviewIcon
                              onClick={() =>
                                handleRowClick(row.id, row.employee_id, "Preview")
                              }
                            />
                            {userRole != "user" && <DeleteIcon
                              onClick={() =>
                                handleRowClick(row.id, row.employee_id, "Delete")
                              }
                            />}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No appraisal data available
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Please select an employee
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </Box>
  );
};

export default History;
